// ClientApp/src/routes/routeConfig.ts
import React from 'react'
import paths from '../../utils/paths'

// Components
import ProtectedReconciliationRoute from '../ProtectedReconciliationRoute'
import StepRedirector from '../StepRedirector'

// Pages
const AccountAssociatedAccountsPage = React.lazy(() => import('../../pages/Account/AccountAssociatedAccounts'))
const AccountDelinquencyPage = React.lazy(() => import('../../pages/Account/AccountDelinquency'))
const AccountingReportsPage = React.lazy(() => import('../../pages/ReportModule/AccountingReports'))
const AccountLayout = React.lazy(() => import('../../pages/Account/AccountLayout'))
const AccountNotesPage = React.lazy(() => import('../../pages/Account/AccountNotes/AccountNotesLoader'))
const AccountOverviewPage = React.lazy(() => import('../../pages/Account/AccountOverview'))
const AccountPaymentsPage = React.lazy(() => import('../../pages/Account/AccountPayments'))
const AccountPropertyAssetListPage = React.lazy(() => import('../../pages/Account/AccountPropertyAssetList'))
const AccountsPage = React.lazy(() => import('../../pages/Accounts'))
const AccountTypePage = React.lazy(() => import('../../pages/AccountTypes'))
const AccountUsersPage = React.lazy(() => import('../../pages/Account/AccountUsers'))
const AdminUsersPage = React.lazy(() => import('../../pages/AdminUsers/AdminUsers'))
const AppraiserRegionsPage = React.lazy(() => import('../../pages/BusinessAppraiserRegions'))
const BasicCostPage = React.lazy(() => import('../../pages/BasicCost'))
const CountySettingsPage = React.lazy(() => import('../../pages/CountySettings'))
const DelinquentAdjustmentPage = React.lazy(() => import('../../pages/Delinquency/DelinquentAdjustment'))
const DelinquentReturnedPaymentPage = React.lazy(() => import('../../pages/Payments/DelinquentReturnedPayment'))
const DelinquentSummaryPage = React.lazy(() => import('../../pages/Payments/DelinquentSummary'))
const DepositTotalsReportsPage = React.lazy(() => import('../../pages/ReportModule/DepositTotals'))
const ForgotPasswordPage = React.lazy(() => import('../../pages/ForgotPassword'))
const HomePage = React.lazy(() => import('../../pages/Home'))
const LeaseMasterAccountNotesPage = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterAccountNotes'),
)
const LeaseMasterAccountsPage = React.lazy(() => import('../../pages/LeaseMaster/LessorAccounts'))
const LeaseMasterAreasPage = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterAreas'),
)
const LeaseMasterLayout = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterLayout'),
)
const LeaseMasterOverviewPage = React.lazy(
  () => import('../../pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterOverview'),
)
const LeaseMasterPaymentPostingPage = React.lazy(() => import('../../pages/LeaseMaster/LessorPaymentPosting'))
const LeaseMasterPaymentReconciliationPage = React.lazy(
  () => import('../../pages/LeaseMaster/LessorPaymentReconciliation'),
)
const LeaseMasterAssetReconciliationPage = React.lazy(() => import('@/pages/LeaseMaster/AssetReconciliation'))
const LeaseMasterDelinquency = React.lazy(
  () => import('@/pages/LeaseMaster/LeaseMasterOverviewSection/LeaseMasterDelinquency'),
)
const LetterAndFormReportsPage = React.lazy(() => import('../../pages/ReportModule/LetterAndForms'))
const Login = React.lazy(() => import('../../pages/Login'))
const MasterHomePage = React.lazy(() => import('../../pages/MasterHome'))
const MasterLogin = React.lazy(() => import('../../pages/MasterLogin'))
const NotificationFilesReportsPage = React.lazy(() => import('../../pages/ReportModule/NotificationFiles'))
const PasswordReset = React.lazy(() => import('../../pages/PasswordReset'))
const PaymentSearchPage = React.lazy(() => import('../../pages/Payments/PaymentSearch'))
const PaymentSummaryPage = React.lazy(() => import('../../pages/Payments/PaymentSummary'))
const PaymentTotalsPage = React.lazy(() => import('../../pages/Payments/PaymentTotals'))
const ProfilePage = React.lazy(() => import('../../pages/Profile/Profile'))
const PropertyClassificationPage = React.lazy(() => import('../../pages/PropertyClassification'))
const ReconciliationAccountPage = React.lazy(() => import('../../pages/Reconciliation/Account'))
const ReconciliationContactsPage = React.lazy(() => import('../../pages/Reconciliation/Contacts'))
const ReconciliationLayout = React.lazy(() => import('../../pages/Reconciliation/Layout'))
const ReconciliationLessorsPage = React.lazy(() => import('../../pages/Reconciliation/Lessors'))
const ReconciliationPropertyAssetsPage = React.lazy(() => import('../../pages/Reconciliation/PropertyAssets'))
const ReconciliationSummaryPage = React.lazy(() => import('../../pages/Reconciliation/FilingSummary'))
const ResetPasswordRequestedPage = React.lazy(() => import('../../pages/ForgotPassword/ResetPasswordRequested'))
const ReturnedPaymentPage = React.lazy(() => import('../../pages/Payments/ReturnedPayment'))
const RolesPage = React.lazy(() => import('../../pages/Roles/Roles'))
const SicCodeMastersPage = React.lazy(() => import('../../pages/SicCodeMasters'))
const SicCodePage = React.lazy(() => import('../../pages/SicCodes'))
const SicSiloCodePage = React.lazy(() => import('../../pages/SicCodes/SicSiloCodesLoader'))
const StatusReportsPage = React.lazy(() => import('../../pages/ReportModule/StatusReports'))
const MissingAppraiserRegionReportPage = React.lazy(
  () => import('../../pages/ReportModule/MissingAppraiserRegions'),
)
const TaxAreaListReportPage = React.lazy(
  () => import('../../pages/ReportModule/TaxAreaList'),
)
const PaymentReportsPage = React.lazy(() => import('../../pages/ReportModule/PaymentReports'))
const SubSiloCodePage = React.lazy(() => import('../../pages/SicCodes/SubSiloCodesLoader'))
const VoidDelinquentPaymentPage = React.lazy(() => import('../../pages/Payments/VoidDelinquentPayment'))
const AppraisalRegionReportPage = React.lazy(() => import('../../pages/ReportModule/AppraisalRegionReport'))
const OverpaymentReportPage = React.lazy(() => import('../../pages/ReportModule/OverpaymentReport'))
const LeaseMasterValueChangePage = React.lazy(() => import('../../pages/ReportModule/LeaseMasterValueChangeReport'))
const DelinquentPostcardReportPage = React.lazy(() => import('../../pages/ReportModule/DelinquentPostcardReport'))
const LeaseMasterSummaryReportPage = React.lazy(() => import('../../pages/ReportModule/LeaseMasterSummaryReport'))
const AccountCommentsReportPage = React.lazy(() => import('../../pages/ReportModule/AccountCommentsReport'))

export type RouteType = {
  path?: string
  component: unknown
  children?: RouteType[]
}

export const publicRoutes: RouteType[] = [
  { path: paths.login, component: Login },
  { path: paths.forgotPassword, component: ForgotPasswordPage },
  { path: paths.resetPasswordSent, component: ResetPasswordRequestedPage },
  { path: paths.passwordReset, component: PasswordReset },
  { path: paths.masterLogin, component: MasterLogin },
]

export const protectedRoutes: RouteType[] = [
  // Home
  { path: paths.home, component: HomePage },

  // LeaseMaster section
  { path: paths.leaseMasterAccountsPage, component: LeaseMasterAccountsPage },
  {
    component: LeaseMasterLayout,
    children: [
      { path: paths.leaseMasterOverviewPage, component: LeaseMasterOverviewPage },
      { path: paths.leaseMasterAreasPage, component: LeaseMasterAreasPage },
      { path: paths.leaseMasterDelinquencyPage, component: LeaseMasterDelinquency },
      { path: paths.leaseMasterNotesPage, component: LeaseMasterAccountNotesPage },
    ],
  },
  {
    path: paths.leaseMasterPaymentPostingPage,
    component: LeaseMasterPaymentPostingPage,
  },
  {
    path: paths.leaseMasterPaymentReconciliationPage,
    component: LeaseMasterPaymentReconciliationPage,
  },
  {
    path: paths.leaseMasterAssetReconciliation,
    component: LeaseMasterAssetReconciliationPage,
  },

  // Account section
  {
    component: AccountLayout,
    children: [
      { path: paths.accountOverview, component: AccountOverviewPage },
      { path: paths.accountAssociatedAccounts, component: AccountAssociatedAccountsPage },
      { path: paths.accountPropertyAssetList, component: AccountPropertyAssetListPage },
      { path: paths.accountPayments, component: AccountPaymentsPage },
      { path: paths.accountDelinquency, component: AccountDelinquencyPage },
      { path: paths.accountNotes, component: AccountNotesPage },
      { path: paths.accountUsers, component: AccountUsersPage },
    ],
  },

  // Reconciliation section
  {
    component: ProtectedReconciliationRoute,
    children: [
      {
        component: ReconciliationLayout,
        children: [
          { path: paths.reconciliation, component: StepRedirector },
          { path: paths.reconciliationAccountInformation, component: ReconciliationAccountPage },
          { path: paths.reconciliationContacts, component: ReconciliationContactsPage },
          { path: paths.reconciliationPropertyAssets, component: ReconciliationPropertyAssetsPage },
          { path: paths.reconciliationLeasedAssets, component: ReconciliationLessorsPage },
          { path: paths.reconciliationSummary, component: ReconciliationSummaryPage },
        ],
      },
    ],
  },

  // Reports section
  { path: paths.accountingReports, component: AccountingReportsPage },
  { path: paths.depositTotalsReports, component: DepositTotalsReportsPage },
  { path: paths.letterAndFormReports, component: LetterAndFormReportsPage },
  { path: paths.notificationFileReports, component: NotificationFilesReportsPage },
  { path: paths.statusReport, component: StatusReportsPage },
  { path: paths.appraisalRegionReport, component: AppraisalRegionReportPage },
  { path: paths.missingAppraiserRegionReport, component: MissingAppraiserRegionReportPage },
  { path: paths.paymentReports, component: PaymentReportsPage },
  { path: paths.accountCommentsReport, component: AccountCommentsReportPage },
  { path: paths.taxAreaList, component: TaxAreaListReportPage },
  { path: paths.overpaymentReport, component: OverpaymentReportPage },
  { path: paths.leaseMasterValueChangeReport, component: LeaseMasterValueChangePage },
  { path: paths.delinquentPostcardReport, component: DelinquentPostcardReportPage },
  { path: paths.leaseMasterSummaryReport, component: LeaseMasterSummaryReportPage },

  // Payments section
  { path: paths.delinquentAdjustment, component: DelinquentAdjustmentPage },
  { path: paths.paymentSearch, component: PaymentSearchPage },
  { path: paths.paymentSummary, component: PaymentSummaryPage },
  { path: paths.paymentTotals, component: PaymentTotalsPage },
  { path: paths.delinquentSummary, component: DelinquentSummaryPage },
  { path: paths.paymentReturnedPayment, component: ReturnedPaymentPage },
  { path: paths.paymentDelinquentYear, component: DelinquentReturnedPaymentPage },
  { path: paths.voidDelinquentPayment, component: VoidDelinquentPaymentPage },

  // Miscellaneous protected routes
  { path: paths.accounts, component: AccountsPage },
  { path: paths.accountType, component: AccountTypePage },
  { path: paths.adminUsers, component: AdminUsersPage },
  { path: paths.appraiserRegions, component: AppraiserRegionsPage },
  { path: paths.basicCost, component: BasicCostPage },
  { path: paths.countySettings, component: CountySettingsPage },
  { path: paths.propertyClassification, component: PropertyClassificationPage },
  { path: paths.profile, component: ProfilePage },
  { path: paths.roles, component: RolesPage },
  { path: paths.sicCodeMasters, component: SicCodeMastersPage },
  { path: paths.sicCodes, component: SicCodePage },
  { path: paths.sicSiloCodes, component: SicSiloCodePage },
  { path: paths.subSiloCodes, component: SubSiloCodePage },
]

export const protectedMasterRoutes: RouteType[] = [
  { path: paths.masterHome, component: MasterHomePage },
  { path: paths.masterAccountType, component: AccountTypePage },
  { path: paths.masterBasicCost, component: BasicCostPage },
  { path: paths.masterPropertyClassification, component: PropertyClassificationPage },
]
