const paths = {
  accountAssociatedAccounts: '/accounts/:accountId/associated-accounts',
  accountCommentsReport: '/report-module/account-comments',
  accountDelinquency: '/accounts/:accountId/delinquency',
  accountingReports: '/report-module/accounting-reports',
  accountNotes: '/accounts/:accountId/notes',
  accountOverview: '/accounts/:accountId/overview',
  accountPayments: '/accounts/:accountId/payments',
  accountPropertyAssetList: '/accounts/:accountId/property-asset-list',
  accounts: '/accounts',
  accountType: '/account-type',
  accountUsers: '/accounts/:accountId/users',
  adminUsers: '/admin-users',
  appraisalRegionReport:  '/report-module/appraisal-region',
  appraiserRegions: '/appraiser-regions',
  basicCost: '/basic-cost',
  countySettings: '/county-settings',
  delinquency: '/delinquency',
  delinquentAdjustment: '/delinquency/adjustments',
  delinquentSummary: '/payments/delinquent-summary',
  depositTotalsReports: '/report-module/deposit-totals',
  forgotPassword: '/forgot-password',
  home: '/',
  leaseMasterAccountsPage: '/lease-master/accounts',
  leaseMasterAreasPage: '/lease-master/accounts/:accountNumber/areas',
  leaseMasterAssetReconciliation: '/lease-master/assets/reconciliation',
  leaseMasterDelinquencyPage: '/lease-master/accounts/:accountNumber/delinquency',
  leaseMasterNotesPage: '/lease-master/accounts/:accountNumber/notes',
  leaseMasterOverviewPage: '/lease-master/accounts/:accountNumber/overview',
  leaseMasterPaymentPostingPage: '/lease-master/payments/posting',
  leaseMasterPaymentReconciliationPage: '/lease-master/payments/reconciliation',
  leaseMasterValueChangeReport: '/report-module/lease-master-value-change',
  letterAndFormReports: '/report-module/letter-and-form',
  login: '/login',
  masterAccountType: '/master/account-type',
  masterBasicCost: '/master/basic-cost',
  masterHome: '/master',
  masterLogin: '/master/login',
  masterPropertyClassification: '/master/property-classification',
  missingAppraiserRegionReport: '/report-module/missing-appraiser-region',
  notificationFileReports: '/report-module/notification-files',
  overpaymentReport: '/report-module/overpayment',
  passwordReset: '/password-reset',
  paymentDelinquentYear: '/payments/delinquent-year',
  paymentReports: '/report-module/payment-reports',
  paymentReturnedPayment: '/payments/returned-payment',
  payments: '/payments',
  paymentSearch: '/payments/search',
  paymentSummary: '/payments/summary',
  paymentTotals: '/payments/daily-totals',
  profile: '/profile',
  propertyClassification: '/property-classification',
  reconciliation: '/reconciliation/:accountId',
  reconciliationAccountInformation: '/reconciliation/:accountId/account',
  reconciliationContacts: '/reconciliation/:accountId/contacts',
  reconciliationLeasedAssets: '/reconciliation/:accountId/leased-assets',
  reconciliationLessors: '/reconciliation/:accountId/lessors',
  reconciliationPropertyAssets: '/reconciliation/:accountId/assets',
  reconciliationSummary: '/reconciliation/:accountId/summary',
  resetPasswordSent: '/forgot-password/sent',
  roles: '/roles',
  sicCodeMasters: '/sic-code-master',
  sicCodes: '/sic-code-list',
  sicSiloCodes: '/sic-silo-list',
  statusReport: '/report-module/status-reports',
  subSiloCodes: '/sic-sub-list',
  taxAreaList: '/report-module/tax-area-list',
  voidDelinquentPayment: '/payments/void-delinquent-payment',
  delinquentPostcardReport: '/report-module/delinquent-postcard-report',
  leaseMasterSummaryReport: '/report-module/lease-master-summary',
} as const

export type Path = typeof paths[keyof typeof paths]

export default paths
